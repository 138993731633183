document.addEventListener("DOMContentLoaded", () => {
    // Мобильное меню
    function mobileNav() {
        // Mobile nav button
        const navBtn = document.querySelector(".header-landing__mob-btn");
        const menuList = document.querySelector(".header-landing__mob-list");

        navBtn.onclick = function () {
            document
                .querySelectorAll(".header-landing__mob-btn-line")
                .forEach((item) => {
                    item.classList.toggle("header-landing__mob-btn-line--open");
                });
            menuList.classList.toggle("active");
        };
    }

    function renderBlogCards() {
        const container = document.querySelector(".blog__slider-wrapper");

        blogCardData.forEach((card) => {
            const cardElement = document.createElement("div");
            cardElement.classList.add("blog__slider-card");

            cardElement.innerHTML = `
      <div class="blog__slider-card-header">
        <img src="img/logo.png" alt="logo">
      </div>
      <div class="blog__slider-card-content">
        <h3>${card.title}</h3>
      </div>
      <div class="blog__slider-card-actions">
        <a role="button" href="${card.link}" target="_blank" class="blog__slider-card-actions-btn">
          Читать
        </a>
        <span class="blog__slider-card-actions-date">${card.date}</span>
      </div>
      `;

            container.appendChild(cardElement);
        });

        // Логика для управления слайдером
        let currentIndex = 0;

        function updateSlider() {
            const sliderWrapper = document.querySelector(
                ".blog__slider-wrapper"
            );
            const card = sliderWrapper.querySelector(".blog__slider-card");
            const cardWidth =
                card.offsetWidth + parseInt(getComputedStyle(card).gap);
            console.log(cardWidth);

            const visibleCards = 3;

            sliderWrapper.style.transform = `translateX(-${
                currentIndex * visibleCards * cardWidth
            }px)`;
        }

        document
            .querySelector(".blog__slider-btn-prev")
            .addEventListener("click", () => {
                if (currentIndex > 0) {
                    currentIndex--;
                    updateSlider();
                }
            });

        document
            .querySelector(".blog__slider-btn-next")
            .addEventListener("click", () => {
                const sliderWrapper = document.querySelector(
                    ".blog__slider-wrapper"
                );
                const totalCards =
                    sliderWrapper.querySelectorAll(".blog__slider-card").length;
                const visibleCards = 3;

                if (currentIndex < Math.ceil(totalCards / visibleCards) - 1) {
                    currentIndex++;
                } else {
                    currentIndex = 0;
                }

                updateSlider();
            });
    }

    function featuresMenu() {
        const listContainer = document.querySelector(".features__list");
        const titleElement = document.querySelector(".features__title");
        const textElement = document.querySelector(".features__text");
        const imageElement = document.querySelector(".features__image");

        function displayFeature(feature) {
            if (titleElement && textElement && imageElement) {
                titleElement.textContent = feature.descr;
                textElement.textContent = feature.text;
                imageElement.src = feature.image;
            }
        }

        featuresData.forEach((dataObj, index) => {
            const listItem = document.createElement("li");

            listItem.innerHTML = `
        <span class="point"></span>
        <span>${dataObj.title}</span>
        <span class="border"></span>
      `;

            listItem.addEventListener("click", () => {
                const isMobile = window.innerWidth <= 959;

                document
                    .querySelectorAll(".features__block-mob")
                    .forEach((block) => block.remove());

                if (
                    listItem.nextElementSibling &&
                    listItem.nextElementSibling.classList.contains(
                        "features__block-mob"
                    )
                ) {
                    listItem.nextElementSibling.remove();
                } else {
                    document
                        .querySelectorAll(".features__list li")
                        .forEach((li) => li.classList.remove("active"));
                    listItem.classList.add("active");

                    if (isMobile) {
                        listItem.insertAdjacentHTML(
                            "afterend",
                            `<div class="features__block-mob">
                <span class="features__title-mob">${dataObj.descr}</span>
                <span class="features__text-mob">${dataObj.text}</span>
              </div>`
                        );
                    }

                    displayFeature(dataObj);
                }
            });

            listContainer.appendChild(listItem);

            if (index === 0) {
                listItem.classList.add("active");

                if (window.innerWidth <= 959) {
                    listItem.insertAdjacentHTML(
                        "afterend",
                        `<div class="features__block-mob">
              <span class="features__title-mob">${dataObj.descr}</span>
              <span class="features__text-mob">${dataObj.text}</span>
            </div>`
                    );
                }
                displayFeature(dataObj);
            }
        });

        window.addEventListener("resize", () => {
            document
                .querySelectorAll(".features__block-mob")
                .forEach((block) => block.remove());
        });
    }

    function renderCards() {
        function createCards() {
            const container = document.querySelector(".service__list");

            cardData.forEach((card, index) => {
                const cardElement = document.createElement("div");
                cardElement.classList.add("service__list-card");
                cardElement.classList.add(`card--${index + 1}`);

                const titleElement = document.createElement("h3");
                titleElement.classList.add("service__list-card-title");
                titleElement.textContent = card.title;

                const dividerElement = document.createElement("span");
                dividerElement.classList.add("service__list-card-divider");

                const listElement = document.createElement("ul");
                listElement.classList.add("service__list-card-list");

                card.points.forEach((point) => {
                    const listItem = document.createElement("li");
                    listItem.textContent = point;
                    listElement.appendChild(listItem);
                });

                cardElement.appendChild(titleElement);
                cardElement.appendChild(dividerElement);
                cardElement.appendChild(listElement);
                container.appendChild(cardElement);
            });
        }
        createCards();
    }

    function getUserSuffix(users) {
        const lastDigit = users % 10;
        const lastTwoDigits = users % 100;

        if (lastTwoDigits >= 11 && lastTwoDigits <= 19) {
            return "пользователей";
        }

        if (lastDigit === 1) {
            return "пользователь";
        }

        if (lastDigit >= 2 && lastDigit <= 4) {
            return "пользователя";
        }

        return "пользователей";
    }
    function getDaySuffix(days) {
        const lastDigit = days % 10;
        const lastTwoDigits = days % 100;

        if (lastTwoDigits >= 11 && lastTwoDigits <= 19) {
            return "дней";
        }

        if (lastDigit === 1) {
            return "день";
        }

        if (lastDigit >= 2 && lastDigit <= 4) {
            return "дня";
        }

        return "дней";
    }
    function createTariffsCard() {

        const container = document.querySelector(".tariffs__list");

        tariffCards.forEach((card) => {
            const cardElement = document.createElement("div");
            cardElement.classList.add("tariffs__list-card");
            cardElement.classList.add(`card--${card.id}`);
            if (card.top) {
                const topElement = document.createElement("div");
                topElement.textContent = "топ продаж";
                topElement.classList.add("tariffs__list-card-top");
                cardElement.appendChild(topElement);
            }

            const headerCard = document.createElement("div");
            headerCard.classList.add("tariffs__list-card-header");
            headerCard.insertAdjacentHTML(
                "afterbegin",
                `<h3 class="tariffs__list-card-header-title">${card.name}</h3>
            <div class="tariffs__list-card-header-info">
              <span class="tariffs__list-card-header-info-price">${card.price}</span>
              <span class="tariffs__list-card-header-info-valute">${card.valute}</span>
              <span class="tariffs__list-card-header-info-divider"></span>
            </div>`
            );

            const contentWrapper = document.createElement("div");
            contentWrapper.classList.add("tariffs__list-card-wrapper");
            const listElement = document.createElement("ul");
            listElement.classList.add("tariffs__list-card-list");

            if (card.edit) {
                listElement.innerHTML = `
                <li class="tariffs__list-card-list-item edit">
                  <span>пользователей</span>
                  <div class="quantity">
                      <input disabled type="number" min="1" max="10" step="1" value="${card.users}">
                      <div class="quantity__buttons">
                          <button class="quantity__buttons-increment-users">
                              <img src="./img/arrow_up.svg" alt="plus"/>
                          </button>
                          <button class="quantity__buttons-decrement-users">
                              <img src="./img/arrow_down.svg" alt="down"/>
                          </button>
                      </div>
                  </div>
                </li>
                <li class="tariffs__list-card-list-item edit">
                  <span>месяцев</span>
                  <div class="quantity">
                      <input disabled type="number" min="1" max="12" step="1" value="${card.period}">
                      <div class="quantity__buttons">
                          <button class="quantity__buttons-increment-period">
                              <img src="./img/arrow_up.svg" alt="plus"/>
                          </button>
                          <button class="quantity__buttons-decrement-period">
                             <img src="./img/arrow_down.svg" alt="down"/>
                          </button>
                      </div>
                  </div>
                </li>`;
            } else {
                listElement.innerHTML = `
                <li class="tariffs__list-card-list-item">
                    <span>${card.users} ${getUserSuffix(card.users)}</span>
                </li>
                <li class="tariffs__list-card-list-item">
                    <span>${card.period} ${getDaySuffix(card.period)}</span>
                </li>`;
            }

            listElement.insertAdjacentHTML(
                "beforeend",
                `
              <li class="tariffs__list-card-list-item">
                  <span>${card.descr_1}</span>
              </li>
              <li class="tariffs__list-card-list-item">
                  <span>${card.descr_2}</span>
              </li>`
            );

            const linkCard = document.createElement("a");
            linkCard.classList.add("tariffs__list-card-link"); // Добавляем необходимые классы
            linkCard.classList.add("open-modal-link");
            linkCard.textContent = card.buttonText;
            linkCard.href = card.link;
            //linkCard.target = "_blank";

            cardElement.appendChild(headerCard);
            container.appendChild(cardElement);
            cardElement.appendChild(contentWrapper);
            contentWrapper.appendChild(listElement);
            contentWrapper.appendChild(linkCard);

            if (card.edit) {
                const userInput = cardElement.querySelector(
                    ".quantity input[type='number']"
                );
                const periodInput = cardElement.querySelectorAll(
                    ".quantity input[type='number']"
                )[1];
                const userIncrementButton = cardElement.querySelector(
                    ".quantity__buttons-increment-users"
                );
                const userDecrementButton = cardElement.querySelector(
                    ".quantity__buttons-decrement-users"
                );
                const periodIncrementButton = cardElement.querySelector(
                    ".quantity__buttons-increment-period"
                );
                const periodDecrementButton = cardElement.querySelector(
                    ".quantity__buttons-decrement-period"
                );

                const priceElement = cardElement.querySelector(
                    ".tariffs__list-card-header-info-price"
                );

                const updatePrice = () => {
                    card.price = userInput.value * periodInput.value * 100;
                    document.getElementById("user-cost-output").textContent = userInput.value * periodInput.value * card.cont_request;
                    priceElement.textContent = card.price;
                };

                userIncrementButton.addEventListener("click", () => {
                    if (parseInt(userInput.value) < parseInt(userInput.max)) {
                        userInput.value = parseInt(userInput.value) + 1;
                        updatePrice();
                    }
                });

                userDecrementButton.addEventListener("click", () => {
                    if (parseInt(userInput.value) > parseInt(userInput.min)) {
                        userInput.value = parseInt(userInput.value) - 1;
                        updatePrice();
                    }
                });

                periodIncrementButton.addEventListener("click", () => {
                    if (
                        parseInt(periodInput.value) < parseInt(periodInput.max)
                    ) {
                        periodInput.value = parseInt(periodInput.value) + 1;
                        updatePrice();
                    }
                });

                periodDecrementButton.addEventListener("click", () => {
                    if (
                        parseInt(periodInput.value) > parseInt(periodInput.min)
                    ) {
                        periodInput.value = parseInt(periodInput.value) - 1;
                        updatePrice();
                    }
                });
            }
        });
    }

    mobileNav();
    featuresMenu();
    renderCards();
    createTariffsCard();
    renderBlogCards();

    const formModal = document.getElementById("formModal");
    const successModal = document.getElementById("successModal");
    const openModalBtns = document.querySelectorAll(".open-modal-btn");
    const closeBtns = document.querySelectorAll(".modal__close");
    const closeBtnsSuccess = document.querySelectorAll(
        ".modal-success__content-close"
    );
    const partnerForm = document.getElementById("partnerForm");
    const agreementCheckboxText = document.querySelector(".modal__check-text");

    // Открытие модального окна при нажатии любой из кнопок
    openModalBtns.forEach((btn) => {
        btn.addEventListener("click", () => {
            formModal.style.display = "flex";
        });
    });

    // Закрытие модальных окон
    closeBtns.forEach((btn) => {
        btn.addEventListener("click", () => {
            formModal.style.display = "none";
            successModal.style.display = "none";
        });
    });

    closeBtnsSuccess.forEach((btn) => {
        btn.addEventListener("click", () => {
            successModal.style.display = "none";
        });
    });

    partnerForm.elements["name"].addEventListener("focus", () => {
        partnerForm.elements["name"].classList.remove("error");
    });
    partnerForm.elements["organization"].addEventListener("focus", () => {
        partnerForm.elements["organization"].classList.remove("error");
    });
    partnerForm.elements["phone"].addEventListener("focus", () => {
        partnerForm.elements["phone"].classList.remove("error");
    });
    partnerForm.elements["agreement"].addEventListener("change", () => {
        if (partnerForm.elements["agreement"].checked) {
            agreementCheckboxText.classList.remove("error");
        } else {
            agreementCheckboxText.classList.add("error");
        }
    });

    // Закрытие модальных окон при клике вне их содержимого
    window.addEventListener("click", (event) => {
        if (event.target === formModal) {
            formModal.style.display = "none";
            partnerForm.elements["name"].classList.remove("error");
            partnerForm.elements["organization"].classList.remove("error");
            partnerForm.elements["phone"].classList.remove("error");
            agreementCheckboxText.classList.remove("error");
            partnerForm.reset();
        }
        if (event.target === successModal) {
            successModal.style.display = "none";
        }
    });

    // Валидация и отправка формы
    partnerForm.addEventListener("submit", (event) => {
        event.preventDefault();

        let hasError = false;

        // Функция для добавления/удаления класса ошибки
        function validateField(field) {
            if (!field) {
                return;
            }

            if (field.value.trim() === "") {
                field.classList.add("error");
                hasError = true;
            } else {
                field.classList.remove("error");
            }
        }

        // Проверка полей и добавление класса ошибки, если они пустые
        validateField(partnerForm.elements["name"]);
        validateField(partnerForm.elements["organization"]);
        validateField(partnerForm.elements["phone"]);

        // Проверка чекбокса согласия отдельно
        const agreementCheckbox = partnerForm.elements["agreement"];
        if (agreementCheckbox && !agreementCheckbox.checked) {
            agreementCheckboxText.classList.add("error");
            hasError = true;
        } else if (agreementCheckbox) {
            agreementCheckboxText.classList.remove("error");
        }

        if (hasError) {
            return;
        }

        // Отправка данных формы
        const formData = new FormData(partnerForm);

        fetch("/api/partner", {
            method: "POST",
            body: formData,
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`HTTP ошибка: ${response.status}`);
                }
                return response.json();
            })
            .then((data) => {
                console.log("Успешный ответ:", data);
                formModal.style.display = "none";
                successModal.style.display = "flex";
                partnerForm.reset();
            })
            .catch((error) => {
                console.error("Ошибка:", error);
                alert(
                    "Произошла ошибка при отправке данных. Попробуйте ещё раз."
                );
            });

        formModal.style.display = "none";
        successModal.style.display = "flex";

        partnerForm.reset();
    });
});
